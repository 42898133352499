body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-item {
  padding-top: 4px;
  padding-bottom: 4px;
}

div[disabled]
{
  pointer-events: none;
  opacity: 0.7;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
}

.filterControls {
  display: flex;
  flex-direction: row;
}

.filterControls > span {
  padding-right: 12px;
  padding-bottom: 8px;
  align-self: center;
}
